import { ImageIcon } from '../../svg'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'; // Import toast
import Modal from '../Modal';

function Dropzone ({ label = "Image", oldImage, handleUpload = (base64Img) => { } }) {
    const [paths, setPaths] = useState([]);
    const [previewModalOpen, setPreviewModalOpen] = useState(false)

    const onDrop = useCallback((acceptedFiles, fileRejections) => {

        fileRejections.forEach(rejection => {
            toast.error(`Invalid file format: ${rejection.file.name}`);
        });

        setPaths(acceptedFiles.map(file => URL.createObjectURL(file)))

        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                console.log("uploaded", binaryStr)
                handleUpload(binaryStr)
            }
        });

    }, [setPaths, handleUpload])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            'image/*': [],
            'application/pdf': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': []
        }
    })

    return (
        <div className='relative h-24 w-full'>
            {!oldImage && <div className={`bg-gray-200 h-24 w-full rounded-lg flex flex-col space-y-1 relative pt-8 cursor-pointer hover:bg-gray-300 ${(oldImage) && 'opacity-100 z-10'}`}  {...getRootProps()}>
                <div className='absolute top-2 w-full flex items-center justify-center'><ImageIcon /></div>
                <input {...getInputProps()} />
                <p className='px-4 opacity-50 flex w-full justify-center text-center'>{label}</p>
                <p className='px-4 text-sm opacity-50 flex w-full justify-center text-center'>Upload Image here</p>
            </div>}

            {/* {paths && paths.map(path =>
                <div className='w-full absolute top-0 h-24 rounded-lg flex items-center justify-center overflow-hidden cursor-pointer z-5'>
                    <img style={{ objectFit: "contain" }} className="object-contain h-24 w-full cursor-pointer" key={path} src={path} />
                </div>)
            } */}
            {oldImage?.serviceUrl &&
                <div className='w-full absolute top-0 h-24 rounded-lg flex items-center justify-center overflow-hidden cursor-pointer z-1'>
                    <img style={{ objectFit: "contain" }} className="object-contain h-24 w-full cursor-pointer" src={oldImage?.serviceUrl} />

                </div>
            }

            {oldImage &&
                <div className={`flex flex-col items-center justify-center text-sm absolute top-0 h-24 w-full opacity-0 ${(oldImage) && 'z-10 hover:opacity-100 bg-white/50'}`}>
                    <span className='text-gray-800 mb-1'>{label}</span>
                    <div className='flex space-x-2'>
                        <span className='button bg-gray-200 px-2 py-1 rounded-md hover:bg-gray-500 hover:text-white cursor-pointer' onClick={() => setPreviewModalOpen(true)}>Preview</span>
                        <span className='button bg-gray-200 px-2 py-1 rounded-md hover:bg-gray-500 hover:text-white cursor-pointer'   {...getRootProps()}>Update <input {...getInputProps()} /></span>
                    </div>
                </div>}
            <Modal
                onDismiss={() => {
                    setPreviewModalOpen(false);
                }}
                hidden
                autoOpen={previewModalOpen}
                onClose={() => { }}
                title={label}
            >
                <img style={{ objectFit: "contain" }} className="object-contain h-full w-full cursor-pointer" src={oldImage?.serviceUrl} />
            </Modal>
        </div>

    )
}

export default Dropzone;
