import { useContext, useEffect } from "react";
import { MapContainer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { toast } from "react-toastify";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsIcon from "@mui/icons-material/Directions";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PinDropIcon from "@mui/icons-material/PinDrop";
import SelectArea from "leaflet-area-select";
import "leaflet/dist/leaflet.css";

import MapFilters from "./common/MapFilterButtons";
import FloatingActionMenu from "../../components/FloatingActionMenu";
import useLeafletMap from "./hooks/useLeafletMap";
import L from "../../helper/CustomLeaflet";
import FloatingInfoMenu from "../../components/common/FloatingMenu";
import MapIsTypingContext from "context/MapIsTypingContext";

export default function LeafletMap({
  handleAssign,
  handleUnassign,
  shipments,
  drivers,
  selectedShipmentIds,
  setSelectedShipmentIds,
  selectedShipments,
  setSelectedShipments,
  selectedDriverId,
  selectedDriver,
  setSelectedDriverId,
  setSelectedDriver,
  filters,
  setFilters,
  handleMapBoundsChange,
  areaSelectRegion,
  setAreaSelectRegion,
}) {
  const {
    map,
    MapControls,
    routeList,
    showShipmentMarkers,
    clearDropoffMarkers,
    showDriverMarkers,
    showShipmentListRoute,
    selectMarkersWithinRegion,
    selectShipmentsOnCheckbox,
    fitMapToBounds,
    removeShipmentRouteLayers,
    handleShowDropoffs,
    controlDropoffMode,
    isDropoffMode,
    dropoffMarkers,
    limitMessage,
    setLimitMessage,
  } = useLeafletMap({
    selectedShipmentIds,
    setSelectedShipmentIds,
    setSelectedShipments,
    selectedDriverId,
    setSelectedDriverId,
    setSelectedDriver,
    filters,
    drivers,
    shipments,
    selectedShipments,
  });

  const { isInputFocused } = useContext(MapIsTypingContext);

  const fitMapViewToSelectedElements = () => {
    let coordinates = [];

    if (filters.shipment.showOnMap) {
      selectedShipments.map((order) =>
        coordinates.push({
          latitude: order.origin.latitude,
          longitude: order.origin.longitude,
        })
      );
    }

    if (filters.driver.showOnMap) {
      if (selectedDriverId) {
        coordinates.push({
          latitude: selectedDriver.latitude,
          longitude: selectedDriver.longitude,
        });
      }
    }

    if (filters.dropoff.showOnMap) {
      selectedShipments.forEach((order) => {
        coordinates.push({
          latitude: order.destination.latitude,
          longitude: order.destination.longitude,
        });
      });
    }

    fitMapToBounds(map, coordinates);
  };

  useEffect(() => {
    if (limitMessage) {
      toast.error(limitMessage);
      setLimitMessage("");
    }
  }, [limitMessage]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "f" && !isInputFocused) {
        fitMapViewToSelectedElements();
      }
      if (e.key === "r" && !isInputFocused && !e.ctrlKey) {
        showSelectedShipmentsRouteOnMap();
      }
      if (e.key === "a" && !isInputFocused) {
        handleAssign();
      }
      if (e.key === "u" && !isInputFocused) {
        handleUnassign();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    selectedShipmentIds,
    selectedDriverId,
    routeList,
    isInputFocused,
    dropoffMarkers,
  ]);

  // Adding tile: Google maps
  useEffect(() => {
    window.L.tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
      maxZoom: 20,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    });
  }, []);

  // Check whether map has loaded
  useEffect(() => {
    if (map) {
      // enable are select SHIFT+CTRL+left click
      map.selectArea.enable();
      map.on("areaselected", (e) => {
        const selectedArea = L.rectangle(e.bounds);
        setAreaSelectRegion(selectedArea);
      });

      map.on(
        "moveend zoomend",
        (function () {
          if (areaSelectRegion) map.removeLayer(areaSelectRegion);

          let timer;
          return function () {
            clearTimeout(timer);
            timer = setTimeout(function () {
              handleMapBoundsChange(
                map.getBounds().getNorthEast().lat,
                map.getBounds().getNorthEast().lng,
                map.getBounds().getSouthWest().lat,
                map.getBounds().getSouthWest().lng
              );
            }, 500);
          };
        })()
      );
    }
  }, [map]);

  useEffect(() => {
    if (areaSelectRegion) {
      selectMarkersWithinRegion(areaSelectRegion);
    }
  }, [areaSelectRegion]);

  // add shipment markers to the map
  useEffect(() => {
    showShipmentMarkers();
  }, [shipments, selectedShipmentIds, map]);

  useEffect(() => {
    // clearDropoffMarkers()
    if (isDropoffMode) handleShowDropoffs();
  }, [selectedShipmentIds, selectedShipments, shipments, map]);

  useEffect(() => {
    selectShipmentsOnCheckbox();
    removeShipmentRouteLayers();
  }, [selectedShipmentIds]);

  useEffect(() => {
    showDriverMarkers();
  }, [filters.driver.showOnMap]);

  useEffect(() => {
    showShipmentMarkers();
  }, [filters.shipment.showOnMap]);

  useEffect(() => {
    controlDropoffMode(filters.dropoff.showOnMap);
  }, [filters.dropoff.showOnMap]);

  // add driver markers to the map
  useEffect(() => {
    showDriverMarkers();
  }, [drivers, selectedDriverId, map]);

  useEffect(() => {
    if (filters.driver.apply == true) {
      showDriverMarkers();
    }
  }, [filters.driver.apply]);

  const showSelectedShipmentsRouteOnMap = () => {
    // remove unselected routes
    removeShipmentRouteLayers();

    if (map && selectedShipments?.length > 0) {
      showShipmentListRoute(selectedShipments);
    } else {
      toast.error("Select at lest 1 order to show the route");
    }
    // prevent map being undraggable
    if (map) {
      map.dragging.enable();
    }
  };

  // add selected driver to the map
  useEffect(() => {
    if (selectedDriverId) {
      const selectedDriver = drivers?.find(
        (driver) => driver.id === selectedDriverId
      );
    }
    // prevent map being undraggable
    if (map) {
      map.dragging.enable();
    }
  }, [selectedDriverId]);

  const floatingMenuActions = [
    {
      icon: <CenterFocusStrongIcon />,
      name: "Focus selected (F)",
      handleClick: () => {
        fitMapViewToSelectedElements();
      },
    },
    {
      icon: <DirectionsIcon />,
      name: "Show Route (R)",
      handleClick: () => {
        showSelectedShipmentsRouteOnMap();
      },
    },
    {
      icon: <DirectionsCarIcon />,
      name: "Assign (A)",
      handleClick: () => handleAssign(),
    },
    {
      icon: <NotInterestedIcon />,
      name: "UnAssign (U)",
      handleClick: () => handleUnassign(),
    },
  ];

  return (
    <div className="max-h-screen w-full flex-1 z-0">
      <MapContainer
        center={[24.719127, 46.669663]} // Riyadh
        zoom={12}
        zoomControl={true}
        style={{ height: "100vh", position: "relative" }}
        tap={false}
        attributionControl={false}
      >
        <MapFilters filters={filters} setFilters={setFilters} />
        <ReactLeafletGoogleLayer
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          type={"roadmap"}
        />

        <FloatingInfoMenu actions={floatingMenuActions} />
        {selectedShipmentIds?.length && (
          <FloatingActionMenu actions={floatingMenuActions} />
        )}
        <MapControls />
      </MapContainer>
    </div>
  );
}
