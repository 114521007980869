import { useMutation } from '@apollo/client';
import { stagingOptions } from 'constants/apollo';

import { ASSIGN_ORDER, ASSIGN_SHIPMENT, MERGE_TWO_SHIPMENTS } from 'api/mutations/AssignShipmentMutation';
import { useEffect } from 'react';
import { toast } from 'react-toastify';


export const useAssignCourier = ({ clearUp = () => { }, selectedShipmentIds }) => {
    const [handleGqlAssign, { data, loading, error }] =
        useMutation(ASSIGN_ORDER, { ...stagingOptions, fetchPolicy: "network-only" });


    useEffect(() => {
        if (data) {
            let text = selectedShipmentIds.join(" ")

            if (!data?.bundleOrdersToShipment?.error?.message) {
                toast.success(`Order ${text} assigned successfully`);

                // Update the statuses based on the response
                const { courier, orders } = data.bundleOrdersToShipment.shipments.reduce(
                    ({ orders }, shipment) => ({
                      orders: [...orders, ...shipment.orders],
                      courier: shipment.courier,
                    }),
                    { orders: [], courier: {} }
                  );

                clearUp({ courier, orders })

            } else {
                toast.error(`Order ${text} can not be assigned`);
            }
        }
    }, [data])

    function handleAssignCourier (selectedDriverId) {
        if (selectedShipmentIds?.length > 0 && selectedDriverId) {
            const idsFormatted = selectedShipmentIds.map(id => Number(id))
            const confirmed = window.confirm("Are you sure you want to assign the selected shipments?");
            if (confirmed) {
                toast.info("Assigning orders...");
                handleGqlAssign({
                    variables: {
                        input: {
                            courierId: Number(selectedDriverId),
                            orderIds: idsFormatted,
                        },
                    },
                })
            }
        } else {
            toast.error("Select at least one order and driver to assign!");
        }
    }

    return { handleAssignCourier }
}
