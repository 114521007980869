import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function useShipmentList({
  reloadingShipment,
  setSelectedShipmentIds,
  setSelectedShipments,
  searchText,
  setSearchText,
}) {
  const { t } = useTranslation("common");

  const selectedRef = useRef(null);
  const [filterAreaOpen, setFilterAreaOpen] = useState(false);
  const [pinnedFilterIds, setPinnedFilterIds] = useState([]);
  const [shipmentLoading, setShipmentLoading] = useState(reloadingShipment);
  const [isLoading, setIsLoading] = useState(false);
  const [limitMessage, setLimitMessage] = useState("");

  const sortOptions = [
    {
      name: "Sort by",
      value: "sort_by",
      id: 6,
      options: [
        { value: "dropoff", label: "Dropoff Location" },
        { value: "pickup", label: "Pickup Location" },
        { value: "merchant", label: "Merchant Name" },
      ],
    },
    {
      name: "Sort order",
      value: "sort_order",
      id: 7,
      options: [
        { value: "asc", label: "Ascending" },
        { value: "desc", label: "Descending" },
      ],
    },
  ];

  const handleDeselectAllItems = () => {
    setSelectedShipmentIds([]);
    setSelectedShipments([]);
    setLimitMessage("");
  };

  const handleShipmentClick = (order) => {
    const id = order.id;
    setSelectedShipmentIds((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((currId) => parseInt(currId) !== parseInt(id));
      } else if (prevState.length < 10) {
        return [id, ...prevState];
      } else {
        setLimitMessage("Limit reached: You can only select up to 10 orders.");
        return prevState;
      }
    });

    setSelectedShipments((prevState) => {
      if (prevState.some((item) => parseInt(item?.id) === parseInt(id))) {
        return prevState.filter((item) => parseInt(item?.id) !== parseInt(id));
      } else if (prevState.length < 10) {
        return [
          {
            ...order,
            number: prevState[0]?.number ? prevState[0]?.number + 1 : 1,
          },
          ...prevState,
        ];
      } else {
        return prevState;
      }
    });
  };

  return {
    t,
    selectedRef,
    searchText,
    setSearchText,
    filterAreaOpen,
    setFilterAreaOpen,
    pinnedFilterIds,
    setPinnedFilterIds,
    shipmentLoading,
    setShipmentLoading,
    handleShipmentClick,
    isLoading,
    setIsLoading,
    sortOptions,
    handleDeselectAllItems,
    limitMessage,
    setLimitMessage
  };
}
