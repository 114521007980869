import { useState, useRef } from "react";
import { useMapEvents } from "react-leaflet";

import { logger } from "../../../helper/logger";
import L from "../../../helper/CustomLeaflet";
import "leaflet.markercluster";

import polyline from "@mapbox/polyline";

import Route from "../../../services/Route";
import { routeColors } from "../../../components/colors";
import { CustomTooltip } from "../../../components/common/Tooltip/index";

import {
  LeafletDriverAnalyticsIMEIIcon,
  LeafletDriverStackIcon,
  LeafletFocusedOrderPickupIcon,
  LeafletGroupShipmentPickupIcon,
  LeafletGroupShipmentPickupIconOneDigit,
  LeafletShipmentStackIconTwoDigit,
  LeafletOrderPickupIcon,
  LeafletShipmentLocationIcon,
  LeafletShipmentStackIconOneDigit,
} from "../../../components/common/leaflet-icons";
import { getDriverIcon } from "../common/getDriverIcon";
import { toast } from "react-toastify";

export default function useLeafletMap({
  selectedShipmentIds,
  setSelectedShipmentIds,
  setSelectedShipments,
  selectedDriverId,
  setSelectedDriverId,
  setSelectedDriver,
  filters,
  drivers,
  shipments,
  selectedShipments,
}) {
  const [map, setMap] = useState(null);
  const [markerClusterShipments, setMarkerClusterShipments] = useState(null);
  const [markerClusterDrivers, setMarkerClusterDrivers] = useState(null);
  const [shipmentMarkers, setShipmentMarkers] = useState([]);
  const [driverMarkers, setDriverMarkers] = useState([]);
  const [dropoffMarkers, setDropoffMarkers] = useState([]);
  const [driverMarkersMap, setDriverMarkersMap] = useState({});
  const [routeList, setRouteList] = useState([]);
  const [directionMarkers, setDirectionMarkers] = useState([]);
  const [limitMessage, setLimitMessage] = useState("");

  const [isDropoffMode, setIsDropoffMode] = useState(false);

  const hubColorMap = useRef({});

  const routeColor = routeColors[4];

  const MapControls = () => {
    const map = useMapEvents({});
    setMap(map);
    return null;
  };

  const handleShipmentSelect = (id) => {
    setSelectedShipmentIds((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((item) => item !== id);
      } else if (prevState.length < 10) {
        return [...prevState, id];
      } else {
        setLimitMessage("Limit reached: You can only select up to 10 orders.");
        return prevState;
      }
    });

    setSelectedShipments((prevState) => {
      if (prevState.some((item) => item?.id == id)) {
        return prevState?.filter((item) => item?.id !== id);
      } else if (prevState.length < 10) {
        const shipment = shipments.find((item) => item?.id == id);
        return [
          {
            ...shipment,
            number: prevState[0]?.number ? prevState[0]?.number + 1 : 1,
          },
          ...prevState,
        ];
      } else {
        return prevState;
      }
    });
  };

  const isShipmentSelected = (id) => {
    return selectedShipmentIds.includes(id);
  };

  const onMarkerClick = (marker, type = "shipment", e) => {
    const id = marker.id;
    if (type === "shipment") {
      handleShipmentSelect(id);
    } else if (type === "dropoff") {
      handleShipmentSelect(id);
    } else {
      if (selectedDriverId === id) {
        setSelectedDriverId(null);
        setSelectedDriver(null);
      } else {
        const driver = drivers.find((item) => item?.id == id);
        setSelectedDriverId(id);
        setSelectedDriver(driver);
      }
    }
  };

  const removeMapDecorations = (decorationList) => {
    decorationList.forEach((decoration) => {
      map.removeLayer(decoration);
    });
  };

  const showDriverMarkers = () => {
    try {
      if (map && drivers && drivers?.length >= 0) {
        const markersCluster = window.L.markerClusterGroup({
          spiderfyOnMaxZoom: false,
          showCoverageOnHover: false,
          zoomToBoundsOnClick: true,
          disableClusteringAtZoom: 10,
          iconCreateFunction: function (cluster) {
            let count = cluster.getChildCount();
            return LeafletDriverStackIcon(count);
          },
        });
        // remove old layer
        if (markerClusterDrivers) {
          removeMapDecorations([markerClusterDrivers]);
        }
        driverMarkers?.map((marker) => {
          // remove icons that are no longer in viewport
          if (!drivers.find((d) => d.id == marker.id)) {
            removeMapDecorations([marker]);
            delete driverMarkersMap[marker.id];
            setDriverMarkers((old) => old.filter((d) => d.id !== marker.id));
          }
        });

        drivers?.map((driver) => {
          showDriverMarker(driver, markersCluster);
        });

        map.addLayer(markersCluster);
        setMarkerClusterDrivers(markersCluster);
      }
    } catch (error) {
      logger("error", error);
    }
  };

  const showDriverMarker = (driver, driverMarkersCluster) => {
    if (!filters.driver.showOnMap) {
      // remove driverMarkers if any

      if (driverMarkers && driverMarkers.length > 0) {
        removeMapDecorations(driverMarkers);
        removeMapDecorations([markerClusterDrivers]);
      }
      setDriverMarkers([]);
      setMarkerClusterDrivers(null);
      setDriverMarkersMap({});
      return;
    }

    // if driver is already in the map, animate it, and move to new position
    if (driverMarkersMap[driver.id]) {
      const marker = driverMarkersMap[driver.id];

      marker.slideTo([driver.latitude, driver.longitude], {
        duration: 300,
      });

      marker.setIcon(getDriverIcon(driver, selectedDriverId));
      driverMarkersCluster.addLayer(marker);
      return;
    }

    // if some drivers are selected, gray icon will be chosen
    if (driver?.latitude && driver?.longitude) {
      let newMarker = window.L.marker([driver?.latitude, driver?.longitude], {
        icon: getDriverIcon(driver, selectedDriverId),
        zIndexOffset: 1000,
      }).on("click", (e) => {
        onMarkerClick(driver, "driver");
      });

      newMarker.id = driver.id;
      driverMarkersCluster.addLayer(newMarker);

      setDriverMarkersMap((prev) => ({
        ...prev,
        [driver.id]: newMarker,
      }));

      setDriverMarkers((prevMarkerList) => [...prevMarkerList, newMarker]);
    }
  };

  const selectShipmentsOnCheckbox = () => {
    const container = document.getElementById("group-shipment-container");
    if (container) {
      selectedShipmentIds.map((id) => {
        let checkbox = document.getElementById(id);
        if (checkbox) checkbox.checked = true;
      });
    }
  };

  const showShipmentMarkers = () => {
    try {
      if (map && shipments && shipments?.length >= 0) {
        if (markerClusterShipments) {
          removeMapDecorations([markerClusterShipments]);
        }
        removeMapDecorations(shipmentMarkers);
        setShipmentMarkers([]);

        const markersCluster = window.L.markerClusterGroup({
          spiderfyOnMaxZoom: false,
          showCoverageOnHover: false,
          zoomToBoundsOnClick: true,

          iconCreateFunction: function (cluster) {
            let count = cluster.getChildCount();
            if (map.getZoom() < 10) {
              return count > 10
                ? LeafletShipmentStackIconTwoDigit(count)
                : LeafletShipmentStackIconOneDigit(count);
            } else if (count < 10) {
              return LeafletGroupShipmentPickupIconOneDigit(count);
            }
            return LeafletGroupShipmentPickupIcon(count);
          },
        });

        // if (!filters.shipment.showOnMap) {
        //   // remove shipmentMarkers if any
        //   if (shipmentMarkers && shipmentMarkers.length > 0) {
        //     removeMapDecorations(shipmentMarkers)
        //     removeMapDecorations([markerClusterShipments])

        //     setShipmentMarkers([])
        //     setMarkerClusterShipments(null)
        //   }
        //   return
        // }

        shipments?.map((shipment) => {
          // check whether shipmentMarkers list has not obj with id === shipment.id
          const isShipmentMarkerExists = shipmentMarkers.some(
            (marker) => marker.id === shipment.id
          );
          if (!isShipmentMarkerExists) {
          }
          // Your logic here if the marker doesn't exist
          showShipmentMarker(shipment, markersCluster);
        });

        markersCluster.on("clusterclick", function (a) {
          if (a.layer._zoom > 10) {
            let container = document.createElement("div");
            container.setAttribute("id", "group-shipment-container");
            container.className = "flex flex-col";

            let title = document.createElement("h5");
            title.innerText = "Group of Shipments";
            container.appendChild(title);

            let markers = a.layer.getAllChildMarkers();

            let list = document.createElement("div");
            list.className =
              "flex flex-col h-40 w-40 space-y-2 overflow-y-scroll pr-4";

            markers.map((marker) => {
              let id = marker.id;

              let domelem = document.createElement("span");
              domelem.className = "font-bold text-md";
              domelem.innerText = marker.id;

              let checkbox = document.createElement("INPUT");
              checkbox.setAttribute("type", "checkbox");
              checkbox.setAttribute("id", id);
              if (isShipmentSelected(id)) {
                checkbox.checked = true;
              }

              checkbox.onchange = function () {
                handleShipmentSelect(id);
              };

              let parent = document.createElement("div");
              parent.className = "flex justify-between";

              parent.appendChild(domelem);
              parent.appendChild(checkbox);
              list.appendChild(parent);
            });

            container.appendChild(list);
            map.openPopup(container, a.layer.getLatLng());
          }
        });

        map.addLayer(markersCluster);
        setMarkerClusterShipments(markersCluster);
      }
    } catch (error) {
      logger("error", error);
    }
  };

  const showShipmentMarker = (shipment, markersCluster) => {
    if (!filters.shipment.showOnMap) {
      // remove shipmentMarkers if any
      if (shipmentMarkers && shipmentMarkers.length > 0) {
        removeMapDecorations(shipmentMarkers);
        removeMapDecorations([markerClusterShipments]);

        setShipmentMarkers([]);
        setMarkerClusterShipments(null);
      }
      return;
    }

    if (shipment.origin) {
      let markerIcon;
      if (selectedShipmentIds?.indexOf(shipment.id) !== -1) {
        markerIcon = LeafletFocusedOrderPickupIcon;
      } // selected shipment icon
      else {
        markerIcon = LeafletOrderPickupIcon;
      } // unselected shipment icon

      let newMarker = window.L.marker(
        [shipment.origin.latitude, shipment.origin.longitude],
        {
          icon: markerIcon,
          name: shipment.id,
        }
      ).on("click", (e) => onMarkerClick(shipment, "shipment", e));

      newMarker.id = shipment.id;
      newMarker.type = "shipment";

      markersCluster.addLayer(newMarker);
      setShipmentMarkers((prevMarkerList) => [...prevMarkerList, newMarker]);
    }
  };

  const handleUpdateSelectedShipmentGroupIcon = (id) => {
    const selectedMarker = shipmentMarkers.find((e) => e.id == id);
    if (selectedShipmentIds?.indexOf(id) !== -1) {
      let visibleOne = markerClusterShipments.getVisibleParent(selectedMarker);
      console.log("sel", visibleOne);
      visibleOne.setIcon(LeafletDriverAnalyticsIMEIIcon);
    }
  };

  const addRouteMarker = (marker, markerouteColor, isCompleted) => {
    let pickupMarker = window.L.marker([marker.latitude, marker.longitude], {
      icon: LeafletShipmentLocationIcon,
    }).addTo(map);

    // bind label to marker
    pickupMarker.bindTooltip(
      CustomTooltip(
        marker.type,
        marker.sequence,
        isCompleted ? "finished" : "notFinished",
        markerouteColor
      ),
      {
        permanent: true,
        direction: "center",
        offset: [13, -18],
        class: "rounded-full",
        style: "background: transparent",
      }
    );
    // assign id to differentiate from other markers
    pickupMarker.id = marker.id;

    // add markers to the list
    setDirectionMarkers((prevDirectionMarkers) => [
      ...prevDirectionMarkers,
      pickupMarker,
    ]);
  };

  const addDriverMarkerToRoute = (driver, markerouteColor, isCompleted) => {
    let driverMarker = window.L.marker([driver.latitude, driver.longitude], {
      // icon: getDriverIcon(driver, selectedDriverId)
    }).addTo(map);

    // bind label to marker
    driverMarker.bindTooltip(
      `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="55" viewBox="0 0 40 55" fill="none">
        <path d="M20.0001 54.1998L10.1275 37.0998L29.8728 37.0998L20.0001 54.1998Z" fill="green"/>
        <circle cx="20" cy="20" r="19" transform="rotate(-180 20 20)" fill="green" stroke="white" stroke-width="2"/>
        <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="9" font-weight="bold" letter-spacing="0.4px"><tspan x="5.30889" y="21.5762">Driver</tspan></text>
        </svg>`,
      {
        permanent: true,
        direction: "center",
        iconSize: [40, 58],
        iconAnchor: [20, 28],
        class: "rounded-full",
        style: "background: transparent",
      }
    );
    // assign id to differentiate from other markers
    driverMarker.id = driver.id;

    // add markers to the list
    setDirectionMarkers((prevDirectionMarkers) => [
      ...prevDirectionMarkers,
      driverMarker,
    ]);
  };

  const showDriverRouteHistory = (driverId, from, to) => {
    return {};
    Route.getRouteHistory({
      driverId: driverId,
      from: from,
      to: to,
    }).then((res) => {
      logger("GOT DRIVER HISTORY ROUTE", res);
    });
  };

  const showShipmentListRoute = (shipmentList) => {
    try {
      if (routeList?.length > 0) {
        // clear up old route icons & lines
        removeShipmentRouteLayers();
        return;
      }

      if (map && shipmentList) {
        toast.info("Showing route, please wait");

        let hasDriver = false;
        let driver = {};
        let driverData = {};
        // define driver data: (1st shipment Pickup for now)
        if (shipmentList[0]?.shipment?.isAssigned) {
          hasDriver = true;
          driver = shipmentList[0]?.shipment?.courier;
          driverData = {
            id: Number(shipmentList[0]?.shipment?.courier?.id),
            currentLocation: {
              latitude: shipmentList[0]?.shipment?.courier?.latitude,
              longitude: shipmentList[0]?.shipment?.courier?.longitude,
            },
          };
          // NOT fully integrated
          // showDriverRouteHistory(
          //   shipmentList[0]?.courier?.id,
          //   shipmentList[0]?.assignTime,
          //   shipmentList[0]?.completeTime
          // )
        } else {
          // set pickup point as driverData
          driverData = {
            id: Number(shipmentList[0]?.id),
            currentLocation: {
              latitude: shipmentList[0]?.origin?.latitude,
              longitude: shipmentList[0]?.origin?.longitude,
            },
          };
        }

        // define dropoffs & set markers
        const shipmentListObj = [];
        const orderStatuses = {};
        shipmentList.map((shipment) => {
          const dropOffs = [];

          let drop = {
            id: Number(shipment.id),
            latitude: shipment.destination.latitude,
            longitude: shipment.destination.longitude,
          };
          dropOffs.push(drop);
          orderStatuses[shipment.id] = shipment.orderStatus;

          const newShipmentObj = {
            id: Number(shipment.id),
            latitude: shipment.origin.latitude,
            longitude: shipment.origin.longitude,
            promise: 0, // idk what it is
            dropoffs: dropOffs,
          };

          shipmentListObj.push(newShipmentObj);
          orderStatuses[shipment.id] = shipment.shipment?.shipmentStatus;
        });

        let data = {
          driver: driverData,
          shipments: shipmentListObj,
          options: {
            g: true,
          },
        };

        Route.optimizeRoute(data).then((res) => {
          let bounds = L.latLngBounds(); // Instantiate LatLngBounds object

          res?.forEach((route, index) => {
            if (route.type !== "end" && route.type !== "start") {
              // add route's markers to the map
              addRouteMarker(
                route,
                routeColor,
                orderStatuses[route.id] === "completed"
              ); // adds start/pickup/delivery markers
            }
            if (
              route.type == "start" &&
              driverData.latitude !== shipmentListObj.latitude &&
              driverData.longitude !== shipmentListObj.longitude
            ) {
              addRouteMarker(
                route,
                routeColor,
                orderStatuses[route.id] === "completed"
              ); // adds start/pickup/delivery markers
            }
            // add marker for driver
            if (hasDriver) addDriverMarkerToRoute(driver, routeColor);

            let polylineRes = route?.geometry;
            let lineOpacity = "1";

            if (
              polylineRes &&
              index !== res.length - 1 &&
              route.type !== "end"
            ) {
              // shipment not started but is assigned: add driver->pickup dash route && opacity: 0.5
              let newRoute = L.polyline(polyline.decode(polylineRes)).addTo(
                map
              );

              setRouteList((prevRouteList) => [...prevRouteList, newRoute]);
              bounds.extend(newRoute.getBounds());

              // Add dashed route if not completed
              if (orderStatuses[route.id] != "completed") {
                let dashRoute = L.polyline(polyline.decode(polylineRes)).addTo(
                  map
                );
                dashRoute.setStyle({
                  color: "white",
                  weight: 1,
                  dashArray: "6, 6",
                  dashOffset: "2",
                });

                dashRoute.id = shipmentList[0].id;
                setRouteList((prevRouteList) => [...prevRouteList, dashRoute]);
              }
              // Make opacity less if not completed route
              if (orderStatuses[route.id] != "completed") {
                lineOpacity = "0.5";
              }
              newRoute.setStyle({
                color: routeColor,
                opacity: lineOpacity,
                weight: 9,
              });
            }
          });
        });
      }
    } catch (error) {
      logger("error setting shipment route", error);
    }
  };

  const selectMarkersWithinRegion = (region) => {
    shipmentMarkers.map((marker) => {
      if (region.getBounds().contains(marker.getLatLng())) {
        const id = marker.id;
        handleShipmentSelect(id);
      }
    });
  };

  const fitMapToBounds = (map, coordinates) => {
    if (coordinates.length === 0) {
      return;
    }

    const bounds = coordinates.reduce((acc, coord) => {
      return acc.extend([coord.latitude, coord.longitude]);
    }, L.latLngBounds());

    routeList.map((route) => {
      bounds.extend(route.getBounds());
    });
    map.flyToBounds(bounds, { duration: 1 });
  };

  const removeShipmentRouteLayers = () => {
    routeList.forEach((route) => {
      if (selectedShipmentIds.indexOf(route.id) === -1) {
        map.removeLayer(route);
      }
      removeMapDecorations(routeList);
      setRouteList([]);
      removeMapDecorations(directionMarkers);
      setDirectionMarkers([]);
    });
  };

  const colorPalette = [
    "#34bfb4",
    "#172b4e",
    "#de340c",
    "#3dde6d",
    "#dd0cc9",
    "#bfac03",
    "#8304bf",
  ];
  let colorIndex = 0;

  function getRandomColor() {
    if (colorIndex < colorPalette.length) {
      return colorPalette[colorIndex++];
    } else {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  }

  const customIcon = (hubId, orderId) => {
    // If the hub doesn't have a color yet and it's selected, assign a new color
    if (!hubColorMap.current[hubId]) {
      hubColorMap.current[hubId] = getRandomColor(); // Define this function to return a random color
    }

    return L.divIcon({
      html: `<div class=${
        selectedShipmentIds.includes(String(orderId)) ? "pin1" : "pin1"
      }
        style="background-color: ${hubColorMap.current[hubId]}; color: ${
        hubColorMap.current[hubId]
      }"><span></span></div>`,
      iconSize: [24, 24],
      iconAnchor: [12, 12],
      zIndexOffset: 10,
    });
  };

  const customIconWithNumber = (hubId, orderId, number) => {
    // If the hub doesn't have a color yet and it's selected, assign a new color
    // if (!hubColorMap.current[hubId]) {
    // hubColorMap.current[hubId] = getRandomColor(); // Define this function to return a random color
    // }

    return L.divIcon({
      html: `<div class="pin1"}
        style="background-color: red; color: ${hubColorMap.current[hubId]}"><span>${number}</span></div>`,
      iconSize: [24, 24],
      iconAnchor: [12, 12],
      zIndexOffset: 10,
    });
  };
  const controlDropoffMode = (isShown) => {
    if (!isShown) {
      setIsDropoffMode(false);

      if (dropoffMarkers?.length > 0) clearDropoffMarkers();

      return;
    }

    setIsDropoffMode(true);
    handleShowDropoffs();
  };

  const handleShowDropoffs = () => {
    if (dropoffMarkers?.length > 0) clearDropoffMarkers();

    const listDropOffs = shipments
      .map((order) => {
        // Fixing the syntax error in the if statement
        if (!selectedShipmentIds.includes(order.id))
          return {
            latitude: order.destination.latitude,
            longitude: order.destination.longitude,
            id: order?.id,
            number: order?.id,
            hubId: order?.hub?.id,
          };
      })
      .filter(Boolean);

    listDropOffs?.forEach((dropOff) => {
      const dropoffMarker = L.marker([dropOff.latitude, dropOff.longitude], {
        icon: customIcon(dropOff?.hubId, dropOff?.id),
      })
        .addTo(map)
        .on("click", (e) => onMarkerClick(dropOff, "dropoff", e));

      dropoffMarker.id = dropOff.id;
      setDropoffMarkers((old) => [...old, dropoffMarker]);
    });

    const listDirNSelected = selectedShipments.map((order) => ({
      latitude: order.destination.latitude,
      longitude: order.destination.longitude,
      id: order.id,
      number: order?.number,
      hubId: order.hub.id,
    }));

    listDirNSelected?.forEach((dropOff) => {
      const dropoffMarker = L.marker([dropOff.latitude, dropOff.longitude], {
        icon: customIconWithNumber(
          dropOff?.hubId,
          dropOff?.id,
          dropOff?.number
        ),
      })
        .addTo(map)
        .on("click", (e) => onMarkerClick(dropOff, "dropoff", e));

      dropoffMarker.id = dropOff.id;
      setDropoffMarkers((old) => [...old, dropoffMarker]);
    });

    // fitMapToBounds(map, listDropOffs)
  };

  const clearDropoffMarkers = () => {
    removeMapDecorations(dropoffMarkers);
    setDropoffMarkers([]);
  };

  return {
    map,
    MapControls,
    routeList,
    setRouteList,
    showShipmentMarkers,
    showDriverMarkers,
    showShipmentListRoute,
    removeMapDecorations,
    directionMarkers,
    setDirectionMarkers,
    selectMarkersWithinRegion,
    selectShipmentsOnCheckbox,
    fitMapToBounds,
    removeShipmentRouteLayers,
    handleShowDropoffs,
    isDropoffMode,
    clearDropoffMarkers,
    controlDropoffMode,
    dropoffMarkers,
    limitMessage,
    setLimitMessage,
  };
}
